<template>
  <div class="drawer-container">
    <div>
      <h3 class="drawer-title">主题风格设置</h3>
      <div class="setting-drawer-block-checbox">
          <div class="setting-drawer-block-checbox-item" @click="handleTheme('#304156')">
            <img src="@/assets/img/dark.svg" alt="dark">
			<div v-if="sideTheme === '#304156'" class="setting-drawer-block-checbox-selectIcon" style="display: block;">
			  <i aria-label="图标: check" class="anticon anticon-check">
				<svg viewBox="64 64 896 896" data-icon="check" width="1em" height="1em" :fill="theme" aria-hidden="true"
					 focusable="false" class="">
				  <path
					d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"/>
				</svg>
			  </i>
			</div>
          </div>
          <div class="setting-drawer-block-checbox-item" @click="handleTheme('#2B68C9')">
            <img src="@/assets/img/light.svg" alt="light">
			<div v-if="sideTheme === '#2B68C9'" class="setting-drawer-block-checbox-selectIcon" style="display: block;">
			  <i aria-label="图标: check" class="anticon anticon-check">
				<svg viewBox="64 64 896 896" data-icon="check" width="1em" height="1em" :fill="theme" aria-hidden="true"
					 focusable="false" class="">
				  <path
					d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"/>
				</svg>
			  </i>
			</div>
          </div>
        </div>
        <el-divider/>
		<div class="drawer-item flex_b" style="padding-bottom: 0;">
		   <span>侧边栏文字颜色</span>
		   <div style="padding-right: 10px;">
			   <el-color-picker
			     v-model="asdTextColor"
			     :predefine="['#409EFF', '#1890ff', '#304156','#212121','#11a983', '#13c2c2', '#6959CD', '#f5222d', ]"
			     class="theme-picker"
			     popper-class="theme-picker-dropdown"
			   />
		   </div>
		  
		</div>
      <div class="drawer-item">
         <span>主题颜色</span>
        <theme-picker style="float: right;height: 26px;margin: -3px 8px 0 0;"  />
      </div>

      <div class="drawer-item">
        <span>开启 Tags-Views</span>
        <el-switch v-model="tagsView" class="drawer-switch" />
      </div>

      <div class="drawer-item">
        <span>固定 Header</span>
        <el-switch v-model="fixedHeader" class="drawer-switch" />
      </div>

      <div class="drawer-item">
        <span>显示 Logo</span>
        <el-switch v-model="sidebarLogo" class="drawer-switch" />
      </div>
      <el-divider/>
    </div>
  </div>
</template>

<script>
import ThemePicker from './ThemePicker'
export default {
  components: { ThemePicker },
  data() {
    return {
		
    }
  },
  computed: {
	  asdTextColor:{
		  get() {
		    return this.$store.state.settings.asdTextColor
		  },
		  set(val) {
		    this.$store.dispatch('settings/changeSetting', {
		      key: 'asdTextColor',
		      value: val
		    })
		  }
	  },
	    theme:{
	        get(){
	          return this.$store.state.settings.theme
	        }
	    },
		sideTheme:{
			get() {
			  return this.$store.state.settings.sideTheme
			},
		},
        fixedHeader: {
          get() {
            return this.$store.state.settings.fixedHeader
          },
          set(val) {
            this.$store.dispatch('settings/changeSetting', {
              key: 'fixedHeader',
              value: val
            })
			
			
          }
        },
		tagsView: {
		  get() {
			return this.$store.state.settings.tagsView
		  },
		  set(val) {
			this.$store.dispatch('settings/changeSetting', {
			  key: 'tagsView',
			  value: val
			})
		  }
		},
		sidebarLogo: {
		  get() {
			return this.$store.state.settings.sidebarLogo
		  },
		  set(val) {
			this.$store.dispatch('settings/changeSetting', {
			  key: 'sidebarLogo',
			  value: val
			})
		  }
		}
  },
  methods: {
        handleTheme(val) {
          this.$store.dispatch('settings/changeSetting', {
            key: 'sideTheme',
            value: val
          })
		  let hoverBg
		  if(val=='#2B68C9'){
		  	hoverBg = 'rgb(34,83,161)'
		  }
		  if(val=='#304156'){
		  	hoverBg = 'rgb(38,52,69)'
		  }
		  this.$store.dispatch('settings/changeSetting', {
		    key: 'hoverBg',
		    value: hoverBg
		  })
        }
       
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-switch{
  width: 40px;
}
.drawer-container {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;

  .drawer-title {
    margin-bottom: 12px;
    color: rgba(0, 0, 0, .85);
    font-size: 14px;
    line-height: 22px;
  }

  .drawer-item {
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    padding: 12px 0;
  }

  .drawer-switch {
    float: right
  }
  .setting-drawer-block-checbox {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 10px;
      margin-bottom: 20px;

      .setting-drawer-block-checbox-item {
        position: relative;
        margin-right: 16px;
        border-radius: 2px;
        cursor: pointer;

        img {
          width: 48px;
          height: 48px;
        }

        .setting-drawer-block-checbox-selectIcon {
          position: absolute;
          top: 0;
          right: 0;
          width: 100%;
          height: 100%;
          padding-top: 15px;
          padding-left: 24px;
          color: #1890ff;
          font-weight: 700;
          font-size: 14px;
        }
      }
    }
}

</style>
