<template>
	<div>
		<div class="floatAssade"
			:style="{top:top,background:sideTheme}" 
			 @mouseleave ="mouseleave()"
			v-if="!out && childList.length!=0"
		>
			 <div
			     @mouseover="mouseover(ind)"
				 class="item"
				 :style="{color:currentMenu==item.url?asdTextColor:'',background:ind==index?hoverBg:''}"
				 v-for="(item,ind) in childList" 
				 :key="ind" 
				 @click="goPath(item)">
				 {{item.title}}
			 </div>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return {
				index:-1,
			}
		},
		props:{
			currentMenu:{
				type:String,
				default:''
			},
			top:{
				type:String,
				default:'70px'
			},
			childList:{
				type:Array,
				default:[]
			},
			out:{
				type:Boolean,
				default:true
			}
		},
		// watch:{
		// 	sideTheme(newVal,oldVal){
		// 		if(newVal=='#2B68C9'){
		// 			this.hoverBg = 'rgb(38,83,161)'
		// 		}
		// 		if(newVal=='#304156'){
		// 			this.hoverBg = 'rgb(34,83,16)'
		// 		}
		// 	}
		// },
		computed:{
			asdTextColor:{
			  get(){
				 return this.$store.state.settings.asdTextColor
			  }
			},
			sideTheme:{
			  get(){
				 return this.$store.state.settings.sideTheme
			  }
			},
			hoverBg:{
			  get(){
				 return this.$store.state.settings.hoverBg
			  }
			},
		},
		methods:{
			mouseover(i){
				this.index = i
			},
			mouseleave(){
				this.$emit('childMouseLeave')
			},
			goPath(item){
				this.$emit('toChildPath',item)
			},
		}
	}
</script>

<style lang="scss" scoped>
	.floatAssade{
	   position: absolute;
	   z-index: 10;
	   left: 70px;
	   width: 180px;
	   color: #fff;
	   border-radius: 4px;
	   .item{
		  cursor: pointer;
		  padding: 10px; 
		  border-radius: 4px;
	   }
	}
</style>
