<template>
  <div>
	  <div class="navbar" :class="fixedHeader?fixedClass:''">
	    <div class="left-menu flex">
	  		<i class="el-icon-s-fold icon" v-if="out" @click="layout"></i>
	  		<i class="el-icon-s-unfold icon" v-if="!out" @click="layout"></i>
	    	<el-breadcrumb class="app-breadcrumb" separator="/">
	    	  <transition-group name="breadcrumb">
	    	    <el-breadcrumb-item v-for="(item,index) in levelList" :key="index">
	    	      <span class="redirect" v-if="index!=levelList.length-1">{{ item.title }}</span>
	  			  <span  class="no-redirect" v-else>{{ item.title }}</span>
	    	    </el-breadcrumb-item>
	    	  </transition-group>
	    	</el-breadcrumb>
	    </div>
	    <div class="right-menu">
	      <el-dropdown
	        class="avatar-container right-menu-item hover-effect" trigger="click">
	        <div class="avatar-wrapper">
	          {{shop_name}}<i class="el-icon-arrow-down el-icon--right"></i>
	        </div>
	        <el-dropdown-menu slot="dropdown">
	          <!-- <el-dropdown-item>主页</el-dropdown-item>
	          <el-dropdown-item>个人中心</el-dropdown-item> -->
	           <el-dropdown-item >
	            <span @click="drawer=true">布局设置</span>
	          </el-dropdown-item>
	          <el-dropdown-item>
	            <span @click="loginOut">退出</span>
	          </el-dropdown-item>
	        </el-dropdown-menu>
	      </el-dropdown>
	    </div>
	  	
	  </div>
	  <div class="drawer">
	  	<el-drawer
	  	   size="300px"
	  	   :with-header="false"
	  	  :visible.sync="drawer"
	  	  :direction="'rtl'">
	  	  <div>
	  		  <layOutSet @close="close"></layOutSet>
	  	  </div>
	  	</el-drawer>
	  </div>
	  <div v-if="fixedHeader" style="height: 60px;">
		  
	  </div>
  </div>
  
</template>

<script>
import layOutSet from './layOutSet.vue'
export default {
  data() {
    return {
		out:true,
		drawer:false,
		fixedClass:'fixed-header-out',
		shop_name:"",
    }
 },
 mounted() {
 	this.shop_name=sessionStorage.getItem('shop_name')
 },
 components:{
	 layOutSet
 },
 props:{
	levelList:{
		type:Array,
		default:[]
	}
 },
  watch:{
	 out(newVal,oldVal){
		 if(newVal){
			 this.fixedClass = 'fixed-header-out'
		 }else{
			  this.fixedClass = 'fixed-header-in'
		 }
	 }, 
  },
  computed: {
    fixedHeader() {
      return this.$store.state.settings.fixedHeader
    }
  },
  methods: {
	  close(){
		  this.drawer = false
	  },
     layout(){
		this.out = !this.out
		this.$emit('changeAsdBar',this.out)
	 },
	 loginOut(){
		 sessionStorage.clear()
		 this.$router.push('/')
	 },
  },
};
</script>

<style lang="scss" scoped>
.fixed-header-out {
	position: fixed;
	top: 0;
	right: 0;
	z-index: 9;
	width: calc(100% - 210px);
	transition: all 0.4s;
}
.fixed-header-in {
	position: fixed;
	top: 0;
	right: 0;
	z-index: 9;
	width: calc(100% - 60px);
	transition: all 0.4s;
}
.navbar {
  height: 50px;
  overflow: hidden;
  background: #fff;
  .icon{
	  font-size: 20px;
	  margin-top: -2px;
	  margin-right: 20px;
	  cursor: pointer;
  }
   .left-menu{
	  position: relative;
	  top:17px;
	  left: 20px;
	  .no-redirect {
	    color: #97a8be;
	    cursor: text;
	  }
	  .redirect{
		  cursor: pointer;
	  }
   }
  .right-menu {
    float: right;
    height: 100%;
   

    &:focus {
      outline: none;
    }
    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        position: relative;
        font-size: 18px;
        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
