<template>
  <div class="tagView ">
     <span class="item main-color-dan" 
		:style="{color:currentPath==item.url?theme:''}"
		v-for="(item,index) in tags" 
		:key="index" 
		@click="toPath(item)">
     	{{item.title}}
		<i class="el-icon-close" @click.stop="remove(item,index)"></i>
     </span>
  </div>
</template>

<script>

export default {
  
  data() {
    return {
      
    }
  },
	computed:{
		theme() {
		  return this.$store.state.settings.theme
		}
	},
  props:{
	 tags:{
		 type:Array,
		 default:[]
	 },
	 currentPath:{
		 type:String,
		 default:''
	 }
  },
  methods: {
	remove(item,index){
		this.tags.splice(index,1)
		if(this.tags.length!=0){
			this.toPath(this.tags[0])
		}
	},  
    toPath(item){
		this.$emit('toChildPath',item)
	},
  },
};
</script>

<style lang="scss" scoped>
	.tagView{
		padding: 10px 0;
		.item{
			height: 30px;
			line-height: 30px;
			padding: 0 8px;
			font-size: 16px;
			background-color: #fff;
			display: inline-block;
			border-radius: 5px;
			cursor: pointer;
			margin-right: 10px ;
			.el-icon-close{
				font-size: 8px;
			}
		}
	}

</style>
