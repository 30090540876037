<template>
  <div class="home">
    <div class="flex container">
	  <div :class="out?'assadeOut':'assadeIn'" class="newAssade" :style="{background:sideTheme}">
		  <div>
			  <div class="logo" v-if="sidebarLogo">
				 <img :src="require('@/assets/logo.png')" alt="">
			  </div>
			  <el-aside>
				  <el-menu :default-active="currentMenu"  :background-color="sideTheme"  
					text-color="#fff" 
					:active-text-color="asdTextColor"
					>
					  <el-submenu style="position: relative;" :index="++i+''" v-for="(el,i) in menuList" :key="i">
						   <template slot="title"> 
							<i class="iconfont iconHover" 
							  :class="el.icon" 
							  style="color: #fff;"
							  @mouseenter="mouseenter($event,i)"
							  >
							</i>  
								<span v-if="out">
									{{el.title}}
								</span>
							</template>
						   <el-menu-item-group v-if="out">
							   <el-menu-item 
									v-for="(item,index) in el.children" 
									:key="index" 
									:index="item.url" 
									@click="goPath(item,i)"
								> 
									<span>{{item.title}}</span> 
							   </el-menu-item>
						   </el-menu-item-group>
					   </el-submenu>
				  </el-menu>
			  </el-aside>
			  
		  </div>
	  </div>
	<!-- :class="out?'assadeOut':'assadeIn'" -->
		  <div class="main" :class="out?'mainOut':'mainIn'">
			  <floatAssade :childList="childList" 
				@toChildPath="toChildPath"
				:currentMenu="currentMenu"
				:out="out"
				:top="top"
				@childMouseLeave="childMouseLeave"
			  >
			  </floatAssade>
			  <navBar :levelList="levelList" @changeAsdBar="changeAsdBar"></navBar>
			  <div class="container">
				 <div :class="fixedHeader?'fixed-header':''" v-if="tagsView">
					 <tagView :currentPath="currentMenu" :tags="tags" @toChildPath="toChildPath"></tagView>
				 </div>
				  <div style="height: 40px;" v-if="fixedHeader && tags.length && tagsView"></div>
				  <div class="app-main">
					  <router-view/>
				  </div>
			  </div>
		  </div>
	   
    </div>
  </div>
</template>


<script>
import navBar from './child/Navbar.vue'
import tagView from './child/tagView.vue'
import floatAssade from './child/floatAssade.vue'
import menuList from './child/menu.js'
export default {
  data() {
    return {
		top:'70px',
		childList:[],
		out:true,
		tags:[],
	    levelList:[
		  {
			  title:'会员管理'
		  },
		  {
			  title:'会员列表'
		  }
	  ],
		currentMenu: "/member/memberList",
		menuList:[]
    };
  },
  components:{
	  navBar,tagView,floatAssade
  },
  computed:{
	  asdTextColor:{
		  get(){
		  	 return this.$store.state.settings.asdTextColor
		  }
	  },
	  sideTheme:{
		  get(){
		  	 return this.$store.state.settings.sideTheme
		  }
	  },
	  sidebarLogo:{
		  get(){
			  return this.$store.state.settings.sidebarLogo
		  }
	  },
	  fixedHeader:{
		 get(){
		 	return this.$store.state.settings.fixedHeader
		 } 
	  },
	  tagsView:{
	  		 get(){
	  		 	return this.$store.state.settings.tagsView
	  		 } 
	  }
  },
  created() {
	this.menuList = menuList.menuList
  },
  watch: {
     $route(to, from) {
		var ele = document.getElementsByClassName('app-main')
		 this.$utils.addClass(ele[0],'fade-enter')
		 setTimeout(()=>{
			 this.$utils.removeClass(ele[0],'fade-enter')
		 },1000)
         this.currentMenu = to.path;
		 // if(to.path=='/globle/shopCheck') return
		 // this.getCheckStatus()
     }
  },
  mounted() {
    this.getUrl()
	// this.getCheckStatus()
  },
  methods: {
	  alertTT(obj){
		  // 0未提交 1 已提交 2 已驳回 3已同意
		   let check_status = obj.check_status
		   let reason = obj.reason
		    if(check_status == 3 || check_status == 1) return 
		   let msg = ''
		   let btxText = ''
		  if(check_status == 0){
			  btxText = '提示'
			  msg = '请完成实名认证'
		  } 
		  if(check_status == 2){
			  btxText = '认证已被驳回'
			  msg = '驳回原因：'+ reason
		  } 
		  this.$alert(msg, btxText, {
			confirmButtonText: '确定',
			callback: action => {
			  this.$router.push('/globle/shopCheck')
			}
		  });
	  },
	  getCheckStatus(){
		  this.$post('/pc/index/getCheckShopData',{},res=>{
		  	let result = res.data
		  	if(result.code==200){
				let obj = {
					check_status:result.data.check_status,
					reason:result.data.reason
				}
				this.alertTT(obj)
		  	}
		  })
	  },
	  mouseenter(e,i){
		  this.top = (e.screenY-120) + 'px'
		  this.childList = this.menuList[i-1].children
		  for(let item of this.childList){
			  item.parent = this.menuList[i-1].title
		  }
	  },
	  childMouseLeave(){
		 this.childList = [] 
	  },
	  changeAsdBar(out){
		this.out = out
	  },
	  getUrl(){
	      let _this = this;
	      let currentUrl = window.location.href;
	      _this.currentMenu = currentUrl.split('#')[1];
	  },
	  goPath(item,i){
		  this.levelList[0].title = this.menuList[i-1].title
		  this.levelList[1].title = item.title
		  let bool =this.tags.some((el,i)=>{
			  return el.title == item.title
		  })
		  item.parent =  this.menuList[i-1].title
		  if(!bool) this.tags.push(item)
	      this.$router.push({path:item.url})
	  },
	  toChildPath(item){
		 this.levelList[0].title = item.parent
		 this.levelList[1].title = item.title
		 let bool =this.tags.some((el,i)=>{
		 	 return el.title == item.title
		 })
		 if(!bool) this.tags.push(item)
		  this.$router.push({path:item.url})
	  },
  }
};
</script>
<style lang="scss">
	.app-main{
		position: relative;
	}
	.fade-enter {
	 animation:mymove 3s linear;
	}
	@keyframes mymove
	{
	  0% {opacity:1}
	  20% {opacity:0}
	  50% {opacity:0.3}
	  70% {opacity:0.8}
	  100% {opacity:1}
	}
	.fixed-header{
		position: fixed;top: 50px;z-index: 5;
	}
	.newAssade{
		overflow-y: scroll;
		overflow-x: hidden;
		scrollbar:1px;
		height: 100vh;
		position: fixed;
		padding: 0;
		z-index: 10;
		.el-menu-item-group__title{
		  padding: 0 !important;
		}
		.logo{
			text-align: center;
			padding: 15px 0 5px;
			img{
				height: 60px;
			}
		}
		
	}
	.newAssade::-webkit-scrollbar{
		width: 0.1px;
	}
	.assadeOut{
		width: 210px;
		transition: all .4s;
	}
	.assadeIn{
		width: 60px;
		transition: all .4s;
	}
	.mainOut{
		margin-left: 200px;
		transition: all .4s;
	}
	.mainIn{
		margin-left: 50px;
		transition: all .4s;
	}
	
	.main{
		   min-height: 100vh;
		   flex: 1;
		   background-color: rgb(245,245,245);
		   overflow-x: scroll;
		   .container{
			   padding: 0px 20px;
		   }
		   
	   }
	

</style>
