var menuList = [
        {
          title: "会员管理",
          id: 2,
          isShow: true,
		  height:'50px',
		  icon:"icon-huiyuanguanli",
          children: [
            { title: "会员列表", url: "/member/memberList" },
            { title: "会员地址", url: "/member/memberAddress" },
            {title:'会员标签',url:"/member/memberTag"},
            // {title:'会员积分',url:"/member/memberScore"},
            // {title:'门店订单',url:"/member/shoppingHistory"},
            {title:'订单列表',url:"/member/historyMemberOrder"}
          ],
        },
        {
          title: "员工管理",
          id: 3,
          isShow: true,
		  height:'50px',
		  icon:"icon-qiyeguanli_yuangongguanli",
          children: [
            { title: "员工列表", url: "/staff/staffList" },
          ],
        },
		{
		  title: "营销管理",
		  id: 4,
		  isShow: true,
		  height:'50px',
		  icon:"icon-yingxiaoguanli",
		  children: [
		    { title: "优惠券", url: "/saletools/couponList" },
			{ title: "积分调整", url: "/saletools/setMemberScore" },
			{ title: "积分流水", url: "/saletools/scoreWater" },
		  ],
		},
        {
          title: "商品管理",
		  icon:"icon-shangpinguanli1",
          id: 5,
          isShow: true,
		  height:'50px',
          children: [
            { title: "商品列表", url: "/goods/goodsList" },
            { title: "商品分类", url: "/goods/goodsClass" }
          ],
        },
        {
          title: "商户管理",
		  icon:"icon-shangpinguanli",
          id: 6,
          isShow: true,
		  height:'50px',
          children: [
            { title: "修改密码", url: "/globle/updateUpwd" },
            { title: "上传图片", url: "/globle/uplodeImg" },
            { title: "全局配置", url: "/globle/config" },
			{ title: "移动端二维码", url: "/globle/h5QrCode" },
			// { title: "商户认证", url: "/globle/shopCheck" },
          ],
        }
      ]
export default{
	menuList
}	  